.report-table {
  $border-color: #aaa; //rgb(20, 54, 96);
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0; //fixed the bug with extra margin
  }
  td {
    padding: 4px 4px !important;
  }
  .row-type-region {
    td {
      background-color: #f4f4f4;
      border-top: 2px solid $border-color;
    }
  }

  .row-type-region-price {
    td {
      background-color: #ecf5f4;
    }
  }
  .row-type-gen-group {
    td {
      //  background-color: #fff;
    }
  }
  .row-type-portfolio,
  .row-type-fuel {
    td {
      border-top: 1px solid $border-color;
    }
  }
  .row-selected {
    td {
      background-color: rgb(210, 232, 227);
    }
  }
}
.theme-dark {
  .report-table {
    $border-color: #222;
    $table-color: #333;
    color: #eee;
    background-color: $table-color;
    .ant-table-small {
      border-color: $border-color;
      border-radius: 4px;
    }
    .ant-table-small > .ant-table-content .ant-table-header {
      background-color: #333;
    }
    thead {
      tr {
        th {
          background: #333 !important;
          color: #fff !important;
          border-color: $border-color !important;
        }
      }
    }
    td {
      color: #ddd;
      border-color: $border-color !important;
    }
    .row-type-region {
      td {
        background-color: #444;
        border-top: 2px solid $border-color;
      }
    }

    .row-type-region-price {
      td {
        background-color: #555;
      }
    }
    .row-type-portfolio,
    .row-type-fuel {
      td {
        background-color: #666;
        border-top: 1px solid $border-color;
      }
    }
    .row-type-gen-group {
      td {
        background-color: $table-color;
      }
    }
  }
}
