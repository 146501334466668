.ant-btn-lg {
    padding: 0 15px !important;
}

.ant-table-header {
    margin-bottom: -8px;
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
    padding: 8px 0px !important;
}

.ant-menu-vertical .ant-menu-item-divider {
    background: #fff !important;
}

.ant-menu-item-group-list {
    padding-left: 30px !important;
}

.ant-menu-item-group-title {
    padding-left: 20px !important;
}

.theme-light .ant-table-cell-row-hover {
    background: rgba(19, 168, 158, 0.25) !important;
  }

.theme-dark .ant-table-cell-row-hover {
    background: rgb(85, 85, 85) !important;
}